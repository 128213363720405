import { ApplicationLayout } from "./ApplicationLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LandingPage } from "./pages";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { theme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { AppConfigurationProvider, Fonts } from "am-tax-fe-core";
import axios from "axios";
import { setAxiosInstance } from "./utils";

function configureHttpClient(baseUrl: string, getAccessToken: () => Promise<string>) {
    const axiosInstance = axios.create({ baseURL: baseUrl });
    axiosInstance.interceptors.request.use(
        async config => {
            const accessToken = await getAccessToken();
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        },
        error => Promise.reject(error)
    );
    setAxiosInstance(axiosInstance);
}

function App() {
    return (
        <ChakraProvider theme={theme} resetCSS>
            <Fonts />
            <BrowserRouter>
                <AppConfigurationProvider
                    envJsonUrl={"/env.json"}
                    onApiContextInit={configureHttpClient}
                    version={{
                        ver: import.meta.env.VITE_BUILD_COMMIT,
                        build: import.meta.env.VITE_BUILD_NUMBER,
                    }}
                >
                    <ErrorBoundary>
                        <UnauthenticatedTemplate>
                            <LandingPage />
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <UserContextProvider>
                                <ApplicationLayout />
                            </UserContextProvider>
                        </AuthenticatedTemplate>
                    </ErrorBoundary>
                </AppConfigurationProvider>
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default App;

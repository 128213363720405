import { FunctionComponent, useMemo, useRef, useState } from "react";
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Select, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestDelete, useRequestDownloadFileFn, useRequests, useRequestSave, useRequestUploadFile } from "../../api";
import { displayRequestStatus, Request, RequestArgs, RequestArgsBody, RequestStatus } from "../../common";
import { SubmitHandler, useForm } from "react-hook-form";
import { DownloadLink, FileContainer, FileUploader, formatTimestampStr, RepeatShimmer, useConfirm, useToast } from "am-tax-fe-core";
import ReactMarkdown from "react-markdown";

type FormValues = Partial<Omit<RequestArgsBody, "years">> & { years?: string };

interface RequestFormProps {
    returnTo: string;
}

export const RequestForm: FunctionComponent<RequestFormProps> = ({ returnTo }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { cId, eId, candidateId, requestId } = useParams();
    const requestsQuery = useRequests(cId, eId, candidateId);
    const request: Request | undefined = useMemo(() => requestsQuery.data?.find(request => request.id === requestId), [requestsQuery.data, requestId]);
    const downloadFn = useRequestDownloadFileFn();

    // ---- Code to Load the Form ----
    const formValues: FormValues = {
        name: request?.name,
        description: request?.description,
        status: request?.status,
    };
    const formRef = useRef<HTMLFormElement>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        values: formValues,
    });

    // ---- Setup Submit Handlers for saving the Form ----
    const saveRequest = useRequestSave();
    const uploadFilesToRequest = useRequestUploadFile();
    const [uploading, setUploading] = useState(false);
    const onSubmit: SubmitHandler<FormValues> = async data => {
        const args: RequestArgs = {
            clientId: cId,
            entityId: eId,
            candidateId: candidateId!, // We can't get here without this
            old: request,
            new: {
                id: requestId,
                name: data.name!, // This is required by react-hook-form
                description: data.description,
                status: data.status!, // This is required by react-hook-form
            },
        };
        saveRequest.mutate(args, {
            onSuccess: data => {
                if (fileQueue.length > 0) {
                    setUploading(true);
                    for (const fileContainer of fileQueue) {
                        uploadFilesToRequest.mutate({ clientId: cId, entityId: eId, candidateId: candidateId!, requestId: data.id, fileContainer });
                    }
                } else {
                    toast({
                        title: "Saved",
                        description: "Request Saved.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    goBackToAllRequests();
                }
            },
        });
    };
    const formSubmit = handleSubmit(onSubmit);

    // ---- Setup the Delete Handler ----
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete Request?", prompt: "Are you sure you want to delete this request?" });
    const deleteRequest = useRequestDelete();
    const doDelete = async () => {
        const result = await confirm();
        if (result) {
            deleteRequest.mutate(
                { requestId: requestId!, candidateId: candidateId! },
                {
                    onSuccess: () => {
                        toast({
                            title: "Deleted",
                            description: "Request Deleted.",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        goBackToAllRequests();
                    },
                }
            );
        }
    };

    // ---- Setup File Upload mockApiResponses ----
    const [fileQueue, setFileQueue] = useState<FileContainer[]>([]);
    const fileQueueRef = useRef<FileContainer[]>(fileQueue);

    const onFileAdded = async (fileContainer: FileContainer) => {
        const queue = [...fileQueueRef.current];
        queue.push(fileContainer);
        setFileQueue(queue);
        fileQueueRef.current = queue;
    };
    const onFileRemoved = async (fileContainer: FileContainer) => {
        const updatedQueue = [];
        const queue = [...fileQueueRef.current];
        for (const file of queue) {
            if (file.index !== fileContainer.index) {
                updatedQueue.push(file);
            }
        }
        setFileQueue(updatedQueue);
        fileQueueRef.current = updatedQueue;
    };

    const onFileUploaded = async (fileContainer: FileContainer, uploadsRemaining: number) => {
        if (uploadsRemaining === 0) {
            setUploading(false);
            setFileQueue([]);
            toast({
                title: "Saved",
                description: "Saved Request and Uploaded Files.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            goBackToAllRequests();
        }
    };

    // ---- Navigation Helper ----
    const goBackToAllRequests = () => {
        navigate(`/clients/${cId}/candidates/${eId}/${candidateId}/${returnTo}`);
    };

    return (
        <>
            <VStack hidden={!requestsQuery.isLoading} spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>

            {!requestsQuery.isLoading && (
                <form ref={formRef} onSubmit={formSubmit}>
                    <fieldset disabled={saveRequest.isLoading || uploading}>
                        <VStack gap={"1rem"}>
                            <Flex gap="2rem" width={"100%"} alignItems={"stretch"}>
                                <VStack spacing="1rem" alignItems="stretch" flexBasis={"50%"}>
                                    <FormControl isInvalid={!!errors?.name}>
                                        <FormLabel>Name:</FormLabel>
                                        <Text>{request?.name}</Text>
                                        <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.description}>
                                        <FormLabel>Preferred Documents:</FormLabel>
                                        <Text>{request?.preferred}</Text>
                                        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.description}>
                                        <FormLabel>Other Documents:</FormLabel>
                                        <Box>
                                            <ReactMarkdown children={request?.others || ""} />
                                        </Box>
                                        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                                <VStack spacing="1rem" alignItems="stretch" flexBasis={"50%"}>
                                    <FormControl isInvalid={!!errors?.status}>
                                        <FormLabel>Status:</FormLabel>
                                        <Select placeholder={"Select a Status"} {...register("status", { required: "Status is required." })}>
                                            {Object.values(RequestStatus).map(status => (
                                                <option key={status} value={status}>
                                                    {displayRequestStatus(status)}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </Flex>
                            {request?.documents?.length && (
                                <VStack width={"full"} minHeight={"6rem"}>
                                    <Table variant="simple" colorScheme={"gray"} size={"sm"}>
                                        <Thead>
                                            <Tr>
                                                <Th>File Name</Th>
                                                <Th>Uploaded</Th>
                                                <Th>Uploaded By</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {request?.documents?.map(document => (
                                                <Tr key={document.id}>
                                                    <Td>
                                                        <DownloadLink
                                                            downloadFn={async progressCallback => {
                                                                const blob = await downloadFn(
                                                                    cId,
                                                                    eId,
                                                                    candidateId!,
                                                                    request.id,
                                                                    document.id,
                                                                    progressCallback
                                                                );
                                                                return { blob, fileName: `${document.name}` };
                                                            }}
                                                        >
                                                            {document.name}
                                                        </DownloadLink>
                                                    </Td>
                                                    <Td>{formatTimestampStr(document.createdOn)}</Td>
                                                    <Td>{document.createdBy?.name}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </VStack>
                            )}
                            <FileUploader onFileAdded={onFileAdded} onFileCancelled={onFileRemoved} onFileUploaded={onFileUploaded} />
                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                {!!requestId && (
                                    <Button
                                        variant="ghost"
                                        onClick={doDelete}
                                        colorScheme={"red"}
                                        fontWeight={200}
                                        isDisabled={uploading || saveRequest.isLoading}
                                        isLoading={deleteRequest.isLoading}
                                        loadingText={"Deleting"}
                                    >
                                        Delete Request
                                    </Button>
                                )}
                                <Spacer />
                                <Button
                                    type="submit"
                                    variant={"primary"}
                                    mr={3}
                                    isLoading={uploading || saveRequest.isLoading}
                                    isDisabled={saveRequest.isLoading}
                                    loadingText={"Saving"}
                                >
                                    Save
                                </Button>
                                <Button variant="ghost" onClick={goBackToAllRequests}>
                                    Cancel
                                </Button>
                            </Flex>
                        </VStack>
                    </fieldset>
                </form>
            )}

            <ConfirmDialog />
        </>
    );
};

﻿import { FunctionComponent, useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, ButtonGroup, Flex, HStack, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Spacer, useDisclosure } from "@chakra-ui/react";
import { useUsers } from "../../api/users";
import { UserResponse, useUserAdSync } from "../../api";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { ICellRendererParams, ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { IconMenu, IconPencil, IconRefresh } from "@tabler/icons-react";
import { EmailCellRenderer } from "../../grid";
import { UserEditDrawer, UserEditDrawerProps } from "./components/UserEditDrawer";
import { AppRoleCellRenderer } from "./components/AppRoleCellRenderer";
import { SelfRegisteredCellRenderer } from "./components/SelfRegisteredCellRenderer";
import { useUserContext } from "../../context/UserContext";
import { SetPageTitle } from "../../components/SetPageTitle";
import { formatTimestampStr } from "am-tax-fe-core";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const AdminUsersPage: FunctionComponent = () => {
    const appInsights = useAppInsightsContext();
    const user = useUserContext();

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/admin/users",
        });
    }, [appInsights]);

    const { data: rowData } = useUsers();
    const userAdSync = useUserAdSync();
    const [selectedUserId, setSelectedUserId] = useState<string>();
    const { isOpen: isUserEditPanelOpen, onOpen: openUserEditPanel, onClose: closeUserEditPanel } = useDisclosure();
    const userActionsCellRenderer = (props: ICellRendererParams<UserResponse>) => {
        return (
            <Flex height="100%" align="center">
                <ButtonGroup>
                    <IconButton
                        aria-label={"edit item"}
                        icon={<IconPencil />}
                        onClick={() => {
                            if (props.data?.id) {
                                setSelectedUserId(props.data.id);
                                openUserEditPanel();
                            }
                        }}
                    />
                </ButtonGroup>
            </Flex>
        );
    };

    const usersGrid: AgGridReactProps<UserResponse> = {
        rowData,

        columnDefs: [
            {
                field: "displayName",
                filter: "text",
            },
            { field: "email", filter: "text", cellRenderer: EmailCellRenderer },
            {
                field: "createdOn",
                filter: false,
                valueFormatter: params => formatTimestampStr(params.value, user.dateFormat),
            },
            { field: "isSelfRegistered", headerName: "Registered", filter: false, cellRenderer: SelfRegisteredCellRenderer },
            { field: "appRoles", headerName: "Role(s)", filter: false, cellRenderer: AppRoleCellRenderer },
            {
                field: undefined,
                headerName: "Actions",
                filter: false,
                sortable: false,
                cellRenderer: userActionsCellRenderer,
            },
        ],
    };

    const userEditDrawer: Omit<UserEditDrawerProps, "userId"> = {
        isOpen: isUserEditPanelOpen,
        onClose: () => {
            setSelectedUserId(undefined);
            closeUserEditPanel();
        },
    };

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <SetPageTitle title={"System Settings"} />
            <HStack p={1}>
                <Spacer />
                <Menu>
                    <MenuButton as={IconButton} aria-label="expand menu" icon={<Icon as={IconMenu} />}></MenuButton>
                    <MenuList>
                        <MenuItem
                            icon={<Icon as={IconRefresh} />}
                            onClick={() => {
                                userAdSync.mutate();
                            }}
                        >
                            AD Sync
                        </MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <Box height="100%" width="100%" className="ag-theme-material">
                <AgGridReact {...usersGrid} />
            </Box>
            {selectedUserId && <UserEditDrawer {...userEditDrawer} userId={selectedUserId} />}
        </Box>
    );
};

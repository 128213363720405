﻿import { defineStyleConfig, extendTheme } from "@chakra-ui/react";
import { theme as coreTheme } from "am-tax-fe-core";

const themeOverrides = {
    components: {
        Badge: defineStyleConfig({
            baseStyle: {
                borderRadius: "full",
                padding: ".1rem .5rem .1rem .5rem",
            },
        }),
    },
};

export const theme = extendTheme(coreTheme, themeOverrides);

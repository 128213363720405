import { FunctionComponent, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody } from "@chakra-ui/react";
import { SetPageTitle } from "../../components/SetPageTitle";
import { useParams } from "react-router-dom";
import { useRequests } from "../../api";
import { Request } from "../../common";
import { RequestForm } from "./RequestForm";
import { Shimmer } from "am-tax-fe-core";
import { useCandidate } from "../../api/hooks/useCandidate";

export const RequestPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const { cId, eId, candidateId, requestId } = useParams();
    const engagementRequestsQuery = useRequests(cId, eId, candidateId);
    const request: Request | undefined = useMemo(
        () => engagementRequestsQuery.data?.find(request => request.id === requestId),
        [engagementRequestsQuery.data, requestId]
    );
    const candidateQuery = useCandidate(cId, eId, candidateId);
    const candidate = candidateQuery.data;

    return (
        <>
            <SetPageTitle title={`${request?.id ? "Edit" : "Add"} Request`} />

            <Breadcrumb mt={"-1.5rem"} mb={"1.5rem"}>
                <BreadcrumbItem>
                    <BreadcrumbLink
                        onClick={() => {
                            navigate(`/clients/${cId}/candidates`);
                        }}
                    >
                        Candidates
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink
                        onClick={() => {
                            navigate(`/clients/${cId}/candidates/${eId}/${candidateId}`);
                        }}
                    >
                        <Shimmer isLoading={candidateQuery.isLoading}>
                            {candidate?.firstName} {candidate?.lastName}
                        </Shimmer>
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>Request</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Card>
                <CardBody>
                    <RequestForm returnTo={""} />
                </CardBody>
            </Card>
        </>
    );
};

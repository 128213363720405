import { FunctionComponent } from "react";
import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { formatTimestampStr } from "am-tax-fe-core";
import { CandidateStatusIcon, getCandidateStatusTitle } from "../../components/CandidateStatus";
import { CandidateActivity } from "../../api/models/EntityCandidate";

export const CandidateTimeline: FunctionComponent<{ candidateActivity: CandidateActivity[] | null | undefined }> = ({ candidateActivity }) => {
    return (
        <Box>
            <HStack flexWrap={"wrap"}>
                {candidateActivity?.map(activity => (
                    <HStack key={activity.id} mt={"5rem"}>
                        <VStack spacing={0} align={"start"}>
                            <Box mr={"1rem"}>{formatTimestampStr(activity.createdOn)}</Box>
                            <ArrowRight colorScheme={"blue"} />
                            {activity.createdBy && <Box>{activity.createdBy.displayName}</Box>}
                        </VStack>
                        <VStack spacing={0} width={"4rem"}>
                            <Box position={"relative"}>
                                <CandidateStatusIcon status={activity.newStatus} boxSize={"1.5rem"} />
                                <Flex transform={"translateY(calc(-50% - .5rem)) translateX(.5rem)"} position={"absolute"} top={"50%"} left={0}>
                                    <Box transformOrigin={"left center"} transform={"rotate(-65deg)"}>
                                        <Box ml={"1rem"}>{getCandidateStatusTitle(activity.newStatus)}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </VStack>
                    </HStack>
                ))}
            </HStack>
        </Box>
    );
};

const ArrowRight: FunctionComponent<{ colorScheme: string }> = ({ colorScheme }) => (
    <Flex height={"1rem"} width={"100%"} align={"center"}>
        <Box
            bg={`${colorScheme}.400`}
            position={"relative"}
            width={"100%"}
            height={"50%"}
            mr={"10%"}
            sx={{
                _after: {
                    content: "''",
                    position: "absolute",
                    bg: `${colorScheme}.400`,
                    top: "-50%",
                    right: "-10%",
                    bottom: "-50%",
                    width: "11%",
                    clipPath: "polygon(0% 0%, 100% 50%, 0% 100%)",
                },
            }}
        ></Box>
    </Flex>
);

﻿import { FC } from "react";
import { Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react";
import { IconLogout, IconLogout2, IconMenu2, IconSelect, IconSettings, IconUserCircle } from "@tabler/icons-react";
import { useMsal } from "@azure/msal-react";
import { useMatch, useNavigate } from "react-router-dom";
import { pageTitleAtom } from "../../atoms";
import { useAtomValue } from "jotai";
import { ApplicationLayoutBoxShadow, TopNav as CoreTopNav } from "am-tax-fe-core";
import { ApplicationRole, useClient } from "../../api";
import { useHasAccess } from "../../hooks/useHasAccess";

export const TopNav: FC = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const pageTitle = useAtomValue(pageTitleAtom);
    const match = useMatch("/clients/:cId/*");
    let clientId = undefined;
    if (match) {
        const { cId } = match.params;
        clientId = cId;
    }
    const clientQuery = useClient(clientId);

    const logout = () => {
        sessionStorage.clear();

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const { hasRole } = useHasAccess();
    const isAdmin = hasRole(ApplicationRole.admin);
    const adminMatch = useMatch("/admin/*");

    return (
        <CoreTopNav
            // @ts-ignore
            title={pageTitle}
            subTitle={clientQuery.data?.name}
            menu={
                <Menu>
                    <MenuButton _hover={{ boxShadow: ApplicationLayoutBoxShadow }} _active={{ boxShadow: ApplicationLayoutBoxShadow }} as={Button}>
                        <IconMenu2 />
                    </MenuButton>
                    <MenuList boxShadow={ApplicationLayoutBoxShadow}>
                        <MenuItem onClick={() => navigate("/profile")} icon={<IconUserCircle />}>
                            Profile
                        </MenuItem>
                        {isAdmin && (
                            <>
                                <MenuItem onClick={() => navigate("/clients")} icon={<IconSelect />}>
                                    Select Client
                                </MenuItem>
                                {adminMatch ? (
                                    <MenuItem onClick={() => navigate("/clients")} icon={<IconLogout />}>
                                        Exit System Settings
                                    </MenuItem>
                                ) : (
                                    <MenuItem onClick={() => navigate("/admin")} icon={<IconSettings />}>
                                        System Settings
                                    </MenuItem>
                                )}
                            </>
                        )}

                        <MenuDivider />
                        <MenuItem onClick={logout} icon={<IconLogout2 />}>
                            Sign out
                        </MenuItem>
                    </MenuList>
                </Menu>
            }
        />
    );
};

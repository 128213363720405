﻿import { FunctionComponent } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { TopNav } from "./components/TopNav";
import { LeftNav } from "./components/LeftNav";
import { PageRoutes } from "./pages";
import { Footer } from "am-tax-fe-core";
import { ErrorBoundary } from "./components/ErrorBoundary";

export const ApplicationLayout: FunctionComponent = () => {
    return (
        <Grid
            templateAreas={` "leftNav topNav"
                             "leftNav main"
                             "footer footer" `}
            gridTemplateRows={"auto 1fr auto"}
            gridTemplateColumns={"auto 1fr"}
        >
            <GridItem as="header" className="page-header" area="topNav">
                <TopNav />
            </GridItem>
            <GridItem as="nav" area="leftNav" minHeight="100vh">
                <LeftNav />
            </GridItem>
            <GridItem as="main" area="main" padding="3rem">
                <ErrorBoundary>
                    <PageRoutes />
                </ErrorBoundary>
            </GridItem>
            <GridItem as="footer" className="page-footer" area="footer">
                <Footer />
            </GridItem>
        </Grid>
    );
};

﻿import { FunctionComponent, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { useHasAccess } from "../../hooks/useHasAccess";
import { ApplicationRole } from "../../api";
import { PageLoading } from "am-tax-fe-core";
import { UnauthorizedPage } from "../../pages/UnauthorizedPage";

export interface AuthorizationCheckProps extends PropsWithChildren {
    requiredRole?: ApplicationRole;
}

export const AuthorizationCheck: FunctionComponent<AuthorizationCheckProps> = props => {
    const { requiredRole } = props;

    const { identity, hasRole } = useHasAccess();

    if (identity == undefined) {
        return <PageLoading text={"Authorization Check..."} />;
    }

    if (identity.roles == undefined || identity.roles.length == 0) {
        return <UnauthorizedPage />;
    }

    const hasRequiredRole = hasRole(requiredRole);

    if (!hasRequiredRole) {
        return <UnauthorizedPage />;
    }

    return <Outlet />;
};

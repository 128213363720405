﻿import { FunctionComponent } from "react";
import { InputType, Question, SyncableValue } from "../../api/models";
import { Input, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import { getYears } from "../../utils";
import { toIsoFormatWithoutTime } from "am-tax-fe-core";

export const RenderInput: FunctionComponent<{ question: Question; value?: SyncableValue; defaultOptionPlaceholder: string, onChange: (newVal: string) => void }> = ({
    question,
    value,
    defaultOptionPlaceholder,
    onChange,
}) => {
    switch (question.input.type) {
        case InputType.text:
            return <Input name={question.id} value={value?.value || ""} onChange={e => onChange(e.target.value)} />;
        case InputType.radio:
            return (
                <RadioGroup name={question.id} value={value?.value || ""} onChange={onChange}>
                    <Stack direction="column">
                        {question.input.options.map(option => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            );
        case InputType.date:
            return <Input name={question.id} 
                          type="date" 
                          value={value?.value || ""}
                          min={question.input.inputOptions?.minDate || ""}
                          max={question.input.inputOptions?.disableFuture ? toIsoFormatWithoutTime(new Date()) : ""}
                          onChange={e => onChange(e.target.value)} />;
        case InputType.select:
            return (
                <Select name={question.id} value={value?.value || ""} placeholder={defaultOptionPlaceholder} onChange={e => onChange(e.target.value)}>
                    {question.input.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            );
        case InputType.year:
            return (
                <Select name={question.id} value={value?.value || ""} placeholder={defaultOptionPlaceholder} onChange={e => onChange(e.target.value)}>
                    {getYears(question.input.inputOptions?.minDate, question.input.inputOptions?.disableFuture).map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </Select>
            );
        default:
            throw new Error("Unknown question type: " + question.input);
    }
};

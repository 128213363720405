﻿import { FunctionComponent, useEffect, useMemo } from "react";
import {
    Button,
    Card,
    Checkbox,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Stack,
    Text,
    Textarea,
    useDisclosure,
} from "@chakra-ui/react";
import { TenantResponse, useDeleteTenant, useTenant, useUpdateTenant } from "../../api";
import { useUserContext } from "../../context/UserContext";
import { CollapsibleDetailsTable } from "../../components/CollapsibleDetailsTable";
import { Controller, useForm } from "react-hook-form";
import { createPatch } from "rfc6902";
import { IconMenu, IconTrash } from "@tabler/icons-react";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "../../components/DeleteConfirmationDialog";
import { formatTimestampStr } from "am-tax-fe-core";

export type ClientEditorDrawerProps = {
    tenantId: string | undefined;
} & Pick<DrawerProps, "isOpen" | "onClose">;
export const TenantEditorDrawer: FunctionComponent<ClientEditorDrawerProps> = props => {
    const { tenantId, ...drawerProps } = props;

    const tenantQuery = useTenant(tenantId),
        tenant = tenantQuery.data;

    const deleteTenant = useDeleteTenant();

    const deleteDialog = useDisclosure(),
        deleteConfirmationDialog: DeleteConfirmationDialogProps = {
            isOpen: deleteDialog.isOpen,
            onClose: deleteDialog.onClose,
            title: "Delete Client",
            isDisabled: false,
            onDelete: () => {
                if (tenantId) {
                    deleteTenant.mutate(tenantId);
                    drawerProps.onClose();
                }
            },
        };

    useEffect(() => {
        deleteDialog.onClose();
    }, [drawerProps.isOpen]);

    return (
        <Drawer {...drawerProps} placement="right" size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Stack direction="row" align="center">
                        <Menu>
                            <MenuButton as={IconButton} aria-label="Options" icon={<Icon as={IconMenu} />} variant="outline" />
                            <MenuList>
                                <MenuItem icon={<Icon as={IconTrash} />} onClick={deleteDialog.onOpen}>
                                    Delete
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        <Text>{tenant?.name}</Text>
                    </Stack>
                </DrawerHeader>

                <DrawerBody>
                    {tenant ? (
                        <Flex gap="2" direction="column">
                            <TenantEditorForm tenant={tenant} onCancel={props.onClose} />
                            <Divider />
                            <TenantAdvancedInfoTable tenant={tenant} />
                        </Flex>
                    ) : (
                        <Spinner />
                    )}
                </DrawerBody>
            </DrawerContent>
            <DeleteConfirmationDialog {...deleteConfirmationDialog}>
                <p>
                    This will remove all access to <strong>{tenant?.name}</strong> and prevent any future operations.
                </p>
                <br />
                <p>
                    Are you <em>sure</em>?
                </p>
            </DeleteConfirmationDialog>
        </Drawer>
    );
};

const TenantEditorForm: FunctionComponent<{ tenant: TenantResponse; onCancel: () => void }> = props => {
    const { tenant, onCancel } = props,
        formValues = useMemo(
            () => ({
                name: tenant.name,
                description: tenant.description ?? "",
                isEnabled: tenant.isEnabled,
                externalId: tenant.externalId,
                customField01: tenant.customField01,
                customField02: tenant.customField02,
                customField03: tenant.customField03,
                customField04: tenant.customField04,
                customField05: tenant.customField05,
            }),
            [tenant]
        );

    const updateTenant = useUpdateTenant();
    const {
        control,
        setValue,
        reset,
        formState: { isDirty },
        register,
        handleSubmit,
    } = useForm();

    useEffect(() => {
        Object.entries(formValues).forEach(([key, value]) => {
            // @ts-ignore
            setValue(key, value);
        });
    }, [formValues, setValue]);

    const formX = {
        onSubmit: handleSubmit(data => {
            const patch = createPatch(formValues, data),
                request = {
                    tenantId: tenant.id,
                    patch: patch,
                };

            updateTenant.mutateAsync(request).then(response => {
                reset({
                    name: response.name,
                    description: response.description ?? "",
                    isEnabled: response.isEnabled,
                    externalId: response.externalId,
                    customField01: response.customField01,
                    customField02: response.customField02,
                    customField03: response.customField03,
                    customField04: response.customField04,
                    customField05: response.customField05,
                });
            });
        }),
    };

    return (
        <form {...formX}>
            <Flex gap="2" direction="column">
                <Controller
                    control={control}
                    render={({ field }) => (
                        <FormControl {...register("isEnabled")}>
                            <FormLabel as="legend">Enabled</FormLabel>
                            <Checkbox isChecked={field.value} onChange={field.onChange} />
                            {field.value === false && <FormHelperText>Disabled clients are not visible to users.</FormHelperText>}
                        </FormControl>
                    )}
                    name={"isEnabled"}
                />
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input placeholder="Client name" {...register("name")} />
                </FormControl>

                <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea placeholder="Client description" {...register("description")} />
                </FormControl>

                <FormControl>
                    <FormLabel>External ID</FormLabel>
                    <Input placeholder="Optional external id" {...register("externalId")} />
                </FormControl>

                <FormControl>
                    <FormLabel>Custom Fields</FormLabel>
                    <Flex gap="2" direction="column">
                        <Input placeholder="Optional custom field 1" {...register("customField01")} />
                        <Input placeholder="Optional custom field 2" {...register("customField02")} />
                        <Input placeholder="Optional custom field 3" {...register("customField03")} />
                        <Input placeholder="Optional custom field 4" {...register("customField04")} />
                        <Input placeholder="Optional custom field 5" {...register("customField05")} />
                    </Flex>
                </FormControl>

                <Flex gap="2" direction="row" justify="end">
                    <Button
                        variant="outline"
                        mr={3}
                        onClick={() => {
                            reset();
                            onCancel();
                        }}
                        isDisabled={updateTenant.isLoading}
                    >
                        Cancel
                    </Button>
                    <Button colorScheme="blue" isDisabled={!isDirty || updateTenant.isLoading} type="submit">
                        Save
                    </Button>
                </Flex>
            </Flex>
        </form>
    );
};

const TenantAdvancedInfoTable: FunctionComponent<{ tenant: TenantResponse }> = props => {
    const { tenant } = props;

    const { dateFormat } = useUserContext();

    const items = [
        { key: "ID", value: tenant.id },
        { key: "Created by", value: tenant.createdByDisplayName },
        { key: "Created on", value: formatTimestampStr(tenant.createdOn, dateFormat) },
        { key: "Updated by", value: tenant.updatedByDisplayName },
        { key: "Updated on", value: formatTimestampStr(tenant.updatedOn, dateFormat) },
        { key: "Database ID", value: tenant.databaseId },
        { key: "Database Name", value: tenant.databaseName },
        { key: "Database Server", value: tenant.databaseServer },
    ];

    return (
        <Card>
            <CollapsibleDetailsTable items={items} caption={"System Info"} />
        </Card>
    );
};

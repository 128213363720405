﻿import { EntityResponse } from "../../../../api";
import { FunctionComponent } from "react";
import { sortBy } from "lodash";
import { Badge, Icon } from "@chakra-ui/react";
import { findEntityById } from "./findEntityById";
import { IconBuilding, IconFolder, IconStars } from "@tabler/icons-react";
import { SingleSelect } from "am-tax-fe-core";

export interface EntitySelectorProps {
    items: EntityResponse[];
    selectedEntityId: string | undefined;
    parentId: string | undefined;
    onSelect: (itemId: string | undefined) => void;
}

export const EntitySelector: FunctionComponent<EntitySelectorProps> = props => {
    const { items, selectedEntityId, onSelect, parentId } = props,
        [, chain] = findEntityById(items, selectedEntityId ?? "");

    const options = sortBy(items, i => i.name).map(i => ({
        key: i.id,
        text: i.name,
        entity: i,
    }));
    const selectedItem = items.find(entity => chain.find(id => id === entity.id)),
        selectedKey = selectedItem?.id ?? "";

    const allCandidateCount = items.reduce((p, c) => p + getTotalCandidateCount(c), 0);

    return (
        <>
            <SingleSelect
                options={[
                    {
                        value: parentId ?? "",
                        label: "All",
                        before: <Icon as={IconStars} />,
                        after: <Badge>{allCandidateCount}</Badge>,
                    },
                    ...options.map(opt => ({
                        value: opt.key,
                        label: opt.text,
                        before: opt.entity?.entityType === "Directory" ? <Icon as={IconFolder} /> : <Icon as={IconBuilding} />,
                        after: <Badge>{opt.entity ? getTotalCandidateCount(opt.entity) : "--"}</Badge>,
                    })),
                ]}
                value={selectedKey || selectedEntityId}
                isSearchable={false}
                onChange={onSelect}
                placeholder={"Select an entity..."}
            />
        </>
    );
};

function getTotalCandidateCount(entity: EntityResponse): number {
    if (entity.children.length === 0) {
        return entity.candidateCount;
    }

    return entity.children.reduce((p, c) => p + getTotalCandidateCount(c), entity.candidateCount);
}
